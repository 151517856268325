/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format } from "@/assets/app.js";
import { Dialog } from 'vant';
export default {
  name: "lcfqNxqj",
  components: {
    LCFQheader
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "年休请假",
      checkbox: false,
      showDataks: false,
      showDataJS: false,
      minDate: new Date(),
      single: false,
      allowSelect: true,
      showUserDialog: false,
      userID: "",
      solId: "",
      actDefId: "",
      glPlaceho: "",
      xmId: "",
      data: {
        ks: "",
        lxgl: "",
        kxts: "",
        yxts: ""
      }
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
    //提前十五天请假
    let times = new Date().getTime() + 15 * 24 * 60 * 60 * 1000;
    this.minDate = new Date(times);
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
    },
    cgORxj() {
      let busad = this.$route.params.busad;
      if (busad != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/annualLeaveForm/annualLeaveForm/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          this.data = res.data;
          let dlrseach = res.data.dlr;
          this.data.dlr = "";
          this.data.jqkssj = format(this.data.jqkssj);
          this.data.jqjssj = format(this.data.jqjssj);
          let urldlr = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
          this.$ajax.post(urldlr).then(resdlr => {
            let dlr = [{
              id: resdlr.data.rowList[0].userId,
              text: resdlr.data.rowList[0].fullname
            }];
            this.data.dlr = JSON.stringify(dlr);
          });
        });
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      this.data.xm = RxUtil.getCache("userName");
      // this.data.ks = RxUtil.getCache("mainGroupName");
      var userID = RxUtil.getCache("userID");
      var xmId = {
        XMID: userID
      };
      var params = new FormData();
      params.append("params", JSON.stringify(xmId));
      var url = _baseUrl + "/customizeProc/annualLeaveForm/annualLeaveForm/getUser.do?ids=";
      axios.post(url).then(res => {
        var entryTime = res.data.entryTime;
        this.xmId = res.data.userId;
        //科室
        let UserId = {
          PARTY2_: res.data.identityId
        };
        let UserIdString = JSON.stringify(UserId);
        let fromDataUserId = new FormData();
        fromDataUserId.append("params", UserIdString);
        let urlks = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
        this.$ajax.post(urlks, fromDataUserId).then(resks => {
          this.data.ks = resks.data.data[0].NAME_;
        });
        //获取当前年份已修天数
        let UserIdXM = {
          USER_ID_: res.data.identityId
        };
        let UserIdStringXM = JSON.stringify(UserIdXM);
        let fromUserIdXM = new FormData();
        fromUserIdXM.append("params", UserIdStringXM);
        let urlhqxm = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqxm.do";
        this.$ajax.post(urlhqxm, fromUserIdXM).then(resXM => {
          //工龄
          var newtime = new Date().getTime();
          var yy = new Date().getFullYear();
          // var gzsj=resXM.data.data[0].JOIN_WORKSJ_; //工作时间
          var gzsj = new Date(resXM.data.data[0].CBSJ_).getTime();
          //获取工作年份
          var y = newtime - gzsj; //undefined
          if (typeof gzsj == 'undefined') {
            this.glPlaceho = "请添加工作时间";
          } else {
            var gznf = Math.floor(y / (86400 * 365 * 1000));
            if (gznf < 1) {
              this.data.lxgl = 0;
            } else {
              this.data.lxgl = gznf;
            }
          }
          //工作年份以及月份
          var d2 = new Date(gzsj);
          var gzn = d2.getFullYear();
          var rzsj = entryTime; //入职时间
          var d1 = new Date(rzsj); //入职年份
          var rzn = d1.getFullYear();
          //当前的年份以及月份
          var d = new Date();
          var dqn = d.getFullYear();
          var dqsj = new Date(d.getFullYear() + "-12-31");
          //入职当前年剩余天数
          var syts = dqsj.getTime() - d1.getTime();
          var syday = Math.abs(parseInt(syts / 1000 / 3600 / 24));
          //工龄判断
          var lxgl = this.data.lxgl;
          //入职时间与当前年份时间比较
          if (rzn < dqn) {
            if (lxgl < 1) {
              this.data.kxts = 0;
            } else if (lxgl >= 1 && lxgl < 10) {
              this.data.kxts = 5;
            } else if (lxgl >= 10 && lxgl < 20) {
              this.data.kxts = 10;
            } else if (lxgl >= 20) {
              this.data.kxts = 15;
            }
          } else if (rzn = dqn) {
            if (lxgl < 1) {
              this.data.kxts = 0;
            } else if (lxgl >= 1 && lxgl < 10) {
              //当前年入职
              let ts1 = parseInt(234 / 365 * 5);
              this.data.kxts = ts1;
            } else if (lxgl >= 10 && lxgl < 20) {
              let ts1 = parseInt(234 / 365 * 10);
              this.data.kxts = ts1;
            } else if (lxgl >= 20) {
              let ts1 = parseInt(234 / 365 * 15);
              this.data.kxts = ts1;
            }
          }
        });
        //-----------已修---------
        let xmId = {
          XMID: res.data.identityId
        };
        let xmIdString = JSON.stringify(xmId);
        let fromXmid = new FormData();
        fromXmid.append("params", fromXmid);
        let urlXMID = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqnj.do";
        this.$ajax.post(urlXMID, fromXmid).then(resXMID => {
          var newData = resXMID.data.data;
          //还没有进行年假请假
          if (newData.length == 0) {
            this.data.yxts = 0;
          } else if (newData.length < 4 && newData.length > 0) {
            var count = 0;
            for (var i = 0; i < newData.length; i++) {
              count += newData[i].SQTS;
            }
            this.data.yxts = count;
          } else if (newData.length >= 4) {
            // Dialog.alert({
            //   title: '提示',
            //   message: '年休假已休完，不可再申请',
            // }).then(() => {
            //   this.$router.push("/index");
            // });
          }
        });
      });
    },
    onConfirmks(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      this.data.jqkssj = year + "-" + month + "-" + date + hours + minutes;
      this.valuechanged();
      this.showDataks = false;
    },
    onConfirmJS(dataTime) {
      let year = dataTime.getFullYear();
      const month = (dataTime.getMonth() + 1).toString().padStart(2, "0");
      const date = dataTime.getDate().toString().padStart(2, "0") + " ";
      const hours = dataTime.getHours() + ":";
      const minutes = dataTime.getMinutes();
      this.data.jqjssj = year + "-" + month + "-" + date + hours + minutes;
      this.valuechanged();
      this.showDataJS = false;
    },
    valuechanged() {
      var yx = this.data.yxts;
      var kx = this.data.kxts;
      ;
      var sysj = kx - yx; //当年剩余可休天数
      var beginrq = this.data.jqkssj;
      var endrq = this.data.jqjssj;
      var date1 = new Date(beginrq);
      var date2 = new Date(endrq);
      var s1 = date1.getTime(),
        s2 = date2.getTime();
      var total = (s2 - s1) / 1000;
      var days = parseInt(total / (24 * 60 * 60)); //计算天数
      var afterDay = total - days * 24 * 60 * 60; //取得算出天数后剩余的秒数
      var xs = parseInt(afterDay / (60 * 60)); //计算小时数
      if (total >= 0) {
        if (days < 1) {
          this.data.sqts = 0;
        } else {
          let xjts = days + Math.floor(xs % 24);
          if (xjts <= sysj) {
            this.data.sqts = xjts;
          } else {
            Dialog.alert({
              title: '提示',
              message: '申请天数大于剩余可休假期'
            });
            this.data.sqts = "申请天数大于剩余可休假期";
          }
        }
      } else {
        this.data.sqts = 0;
      }
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    datas() {
      let dlr = JSON.parse(this.data.dlr);
      var jsonDataObj = {
        persid: "",
        xmId: this.xmId,
        xm: this.data.xm,
        ks: this.data.ks,
        lxgl: this.data.lxgl,
        kxts: this.data.kxts,
        yxts: this.data.yxts,
        sqts: this.data.sqts,
        jqkssj: this.data.jqkssj,
        jqjssj: this.data.jqjssj,
        dlr: dlr[0].text,
        qdbsx: this.data.qdbsx,
        instId: "",
        SUB_checkhilist: []
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      if (this.data.sqts == "申请天数大于剩余可休假期" || typeof this.data.sqts == "undefined") {
        this.errorToast("请检查申请条件！", "1800");
      } else {
        var params = this.datas();
        var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
        this.$dialog.loading.open('正在启动流程,请稍候...');
        this.$ajax.post(url, params).then(res => {
          this.$dialog.loading.close();
          var data = res.data;
          if (data.success) {
            this.$store.commit('cleanInstData');
            this.successToast("启动成功！", 1000, 'index', {
              from: 'startForm'
            });
          } else {
            this.errorToast("启动失败！" + data.data, "1800");
          }
        });
      }
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};